export const environment = {
  name: 'dev-r2',
  api: {
    baseUrl: 'https://api.svil.tinaba.it',
    domain: 'http://tinaba-dev2-apigw-service.webscience.it',
    securityUrl: '/api/sec/v2',
    userUrl: '/api/usr/v2',
    ecommerceUrl: '/api/ecm/v2',
    deviceUrl: '/api/sec/v2/oauth',
    notificationUrl: '/api/not/v2',
    busUrl: '/api/bus/v2'
  },
  login: {
    refreshToken: null
  },
  analytics: {
    id: 'UA-93338880-1'
  },
  push: {
    android: {
         senderID: '363513418506'
     },
     ios: {
         alert: 'true',
         badge: true,
         sound: 'true',
         clearBadge: 'true'
     },
     windows: {}
  },
  environmentName: 'Development Environment',
  iosPushSandbox: false,
};
